/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import {configureFonts, getFontThemeList} from '@nfq/next-fonts';
import {createGlobalStyle, keyframes} from 'styled-components';

import type {Config} from '@nfq/react-grid';
import type {DefaultTheme} from 'styled-components';

export const fontDefinitions = configureFonts({
    OnAir: [
        {
            fontDisplay: 'swap',
            fontStyle: 'italic',
            fontWeight: 'bold',
            preload: false,
            src: '/fonts/OnAir-BoldItalic.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: 'bold',
            preload: true,
            src: '/fonts/OnAir-Bold.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'italic',
            fontWeight: 'normal',
            preload: false,
            src: '/fonts/OnAir-Italic.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: 'normal',
            preload: true,
            src: '/fonts/OnAir-Regular.woff2'
        }
    ]
});

const nfqgrid: Config = {
    baseSpacing: 0.4,
    container: {
        lg: 'fluid',
        md: 'fluid',
        sm: 'fluid',
        xl: 'fluid',
        xs: 'fluid',
        xxl: 1920
    },
    containerPadding: {
        lg: 80,
        md: 80,
        sm: 80,
        xl: 80,
        xs: 80,
        xxl: 80
    }
};

export enum Colors {
    /** Anchor font color. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    anchorColor = '#0019A5',
    /** Asset background color. ![#E5EAEE](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    assetBackgroundColor = '#E5EAEE',
    /** Asset active border color. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    assetActiveBorderColor = '#0019A5',
    /** Asset active overlay color. ![#0019A533](https://via.placeholder.com/12/0019A533/0019A533.png) `#0019A533`. */
    assetActiveOverlayColor = 'rgba(25, 38, 48, 0.2)',
    /** Focus border color for buttons. ![#41B6E6](https://via.placeholder.com/12/41B6E6/41B6E6.png) `#41B6E6`. */
    buttonBorderColor = '#41B6E6',
    /** Background for dark buttons. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    buttonDarkColor = '#0019A5',
    /** Background for dark buttons on hover. ![#263BB2](https://via.placeholder.com/12/263BB2/263BB2.png) `#263BB2`. */
    buttonDarkHoverColor = '#263BB2',
    /** Background for dark buttons on pressed. ![#001694](https://via.placeholder.com/12/001694/001694.png) `#001694`. */
    buttonDarkPressedColor = '#001694',
    /** Background for light buttons. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    buttonLightColor = '#FFFFFF',
    /** Background for light buttons on hover. ![#F3F5F6](https://via.placeholder.com/12/F3F5F6/F3F5F6.png) `#F3F5F6`. */
    buttonLightHoverColor = '#F3F5F6',
    /** Background for light buttons on pressed. ![#E5EAEE](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    buttonLightPressedColor = '#E5EAEE',
    /** Dark border for white elements on white background. ![#D8D8D8](https://via.placeholder.com/12/D8D8D8/D8D8D8.png) `#D8D8D8`. */
    darkBorderColor = '#D8D8D8',
    /** Chackbox background color if checked. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    checkboxCheckedColor = '#0019A5',
    /** Chackbox background color if unchecked. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    checkboxUncheckedColor = '#FFFFFF',
    /** Content background color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    contentBackgroundColor = '#FFFFFF',
    /** Background color for dark elements. ![#192630](https://via.placeholder.com/12/192630/192630.png) `#192630`. */
    darkBackgroundColor = '#192630',
    /** Body Font color. ![#192630](https://via.placeholder.com/12/192630/192630.png) `#192630`. */
    defaultFontColor = '#192630',
    /** Success background Color. ![#F65D7C](https://via.placeholder.com/12/F65D7C/F65D7C.png) `#F65D7C`. */
    errorBackgroundColor = 'rgba(246, 93, 124, 0.1)',
    /** Success Color. ![#F65D7C](https://via.placeholder.com/12/F65D7C/F65D7C.png) `#F65D7C`. */
    errorColor = '#F65D7C',
    /** The inactive color of filter selects. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    filterSelectActiveColor = '#0019A5',
    /** The inactive color of filter selects. ![#7A8894](https://via.placeholder.com/12/7A8894/7A8894.png) `#7A8894`. */
    filterSelectInactiveColor = '#7A8894',
    /** Background color for the header and login. ![#000066](https://via.placeholder.com/12/000066/000066.png) `#000066`. */
    headerBackgroundColor = '#000066',
    /** Error background color for Inputs. ![#FEF6F8](https://via.placeholder.com/12/FEF6F8/FEF6F8.png) `#FEF6F8`. */
    inputErrorBackgroundColor = '#FEF6F8',
    /** Default background for dark inputs. ![#F3F5F6](https://via.placeholder.com/12/F3F5F6/F3F5F6.png) `#F3F5F6`. */
    inputDarkDefaultBackgroundColor = '#F3F5F6',
    /** Default background for light inputs. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    inputLightDefaultBackgroundColor = '#FFFFFF',
    /** Hover background for all inputs. ![#E5EAEE](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    inputHoverBackgroundColor = '#E5EAEE',
    /** Focus border for all inputs. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    inputFocusBorderColor = '#0019A5',
    /** Page background color. ![#F3F5F6](https://via.placeholder.com/12/F3F5F6/F3F5F6.png) `#F3F5F6`. */
    pageBackground = '#F3F5F6',
    /** Pagination active color. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    paginationActiveColor = '#0019A5',
    /** Dark background color for Pagination. ![#F3F5F6](https://via.placeholder.com/12/F3F5F6/F3F5F6.png) `#F3F5F6`. */
    paginationDarkBackgroundColor = '#F3F5F6',
    /** Hover color for Pagination. ![#E5EAEE](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    paginationHoverColor = '#E5EAEE',
    /** Light background color for Pagination. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    paginationLightBackgroundColor = '#FFFFFF',
    /** Font color for Pagination. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    paginationFontColor = '#0019A5',
    /** Font color for active Pagination. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    paginationActiveFontColor = '#FFFFFF',
    /** Placeholder font color. ![#7A8894](https://via.placeholder.com/12/7A8894/7A8894.png) `#7A8894`. */
    placeholderColor = '#7A8894',
    /** Popover background color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    popoverBackgroundColor = '#FFFFFF',
    /** Primary Font color. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    primaryFontColor = '#0019A5',
    /** Secondary Font color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    secondaryFontColor = '#FFFFFF',
    /** Border color for sidbar. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    sidebarBackgroundColor = '#FFFFFF',
    /** Border color for sidbar. ![#E1E1E1](https://via.placeholder.com/12/E1E1E1/E1E1E1.png) `#E1E1E1`. */
    sidebarBorderColor = '#E1E1E1',
    /** Text color for label within sidbar. ![#7A8894](https://via.placeholder.com/12/7A8894/7A8894.png) `#7A8894`. */
    sidebarLabelColor = '#7A8894',
    /** Success background Color. ![#74CF9A](https://via.placeholder.com/12/74CF9A/74CF9A.png) `#74CF9A`. */
    successBackgroundColor = 'rgba(116, 207, 154, 0.1)',
    /** Success Color. ![#74CF9A](https://via.placeholder.com/12/74CF9A/74CF9A.png) `#74CF9A`. */
    successColor = '#74CF9A',
    /** Table Head font color if sorting is active. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    tableActiveHeadColor = '#0019A5',
    /** Table border color. ![#E5EAEE](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    tableBorderColor = '#E5EAEE',
    /** Table dark background color. ![#F3F5F6](https://via.placeholder.com/12/F3F5F6/F3F5F6.png) `#F3F5F6`. */
    tableDarkBodyColor = '#F3F5F6',
    /** Table Head font color. ![#7A8894](https://via.placeholder.com/12/7A8894/7A8894.png) `#7A8894`. */
    tableHeadColor = '#7A8894',
    /** Table Hover background color. ![#E5EAEE](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    tableHoverBodyColor = '#E5EAEE',
    /** Table Popup font color. ![#DAE1E7](https://via.placeholder.com/12/DAE1E7/DAE1E7.png) `#DAE1E7`. */
    tablePopoverBackgroundColor = '#DAE1E7',
    /** Ultra light font color. ![#CCD5DD](https://via.placeholder.com/12/CCD5DD/CCD5DD.png) `#CCD5DD`. */
    ultraLightFontColor = '#CCD5DD'
}

export enum BoxShadows {
    /** The default dropshadow for the board-header. */
    headerShadow = '0px 4px 9px 4px rgba(0, 0, 0, 0.05)',
    /** The default dropshadow for Popovers. */
    popOverShadow = '0px 0px 10px 5px rgba(25, 38, 48, 0.05)',
    /** The default dropshadow for Sidebars. */
    sidebarShadow = '0px 0px 12px 2px rgba(0, 0, 0, 0.1)'
}

export const theme: DefaultTheme = {
    boxShadows: BoxShadows,
    colors: Colors,
    fonts: getFontThemeList(fontDefinitions),
    nfqgrid
};

const skeleton = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

export const GlobalStyle = createGlobalStyle`
    *,
    &::before,
    &::after {
        box-sizing: border-box;
    }

    * {
        -webkit-tap-highlight-color: transparent;
    }

    html {
        font-size: 10px;
    }

    html, body {
        background-color: ${({theme: globalTheme}) => globalTheme.colors.pageBackground};
        color: ${({theme: globalTheme}) => globalTheme.colors.defaultFontColor};
        height: 100%;
        margin: 0;
        min-height: 100%;
        padding: 0;
        scroll-behavior: smooth;
    }

    #__next {
        background-color: ${({theme: globalTheme}) => globalTheme.colors.pageBackground};
        height: 100%;
        min-height: 100%;
    }

    .react-loading-skeleton {
        --base-color: rgba(255, 255, 255, 0.3);
        --base-color-highlight: rgba(255, 255, 255, 0);
        --highlight-color: rgba(255, 255, 255, 0.3);
        --animation-duration: 1.5s;
        --animation-direction: normal;
        --pseudo-element-display: block; /* Enable animation */

        background-color: var(--base-color);
        border-radius: 0.4rem;
        display: inline-flex;
        line-height: 1;
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
    }

    .react-loading-skeleton::after {
        animation-direction: var(--animation-direction);
        animation-duration: var(--animation-duration);
        animation-iteration-count: infinite;
        animation-name: ${skeleton};
        animation-timing-function: ease-in-out;
        background-image: linear-gradient(90deg, var(--base-color-highlight), var(--highlight-color), var(--base-color-highlight));
        background-repeat: no-repeat;
        content: ' ';
        display: var(--pseudo-element-display);
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        transform: translateX(-100%);
    }

    .react-loading-skeleton.dark {
        --base-color: rgba(0, 0, 102, 0.3);
        --base-color-highlight: rgba(0, 0, 102, 0);
        --highlight-color: rgba(0, 0, 102, 0.3);
    }
`;